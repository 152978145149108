<template>
  <div>
    <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; " >
      <md-field class="dialogField" style="width: 300px">
        <label>Укажите идентификатор события</label>
        <md-input v-model="selectedMessageId" placeholder="укажите идентификатор сообщения" ></md-input>
        <span class="md-error">Должно быть указано положительное число</span>
      </md-field>
      <md-button class="md-raised md-primary" @click="goToSelectedMessage">Перейти к сообщению</md-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ExpertEmpty',
    data() {
      return {
        selectedMessageId: null
      }
    },
    methods: {
      goToSelectedMessage() {
        this.$router.push(`/expert/${this.selectedMessageId}`)
      }
    }
  }
</script>